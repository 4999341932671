.bottom-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  margin: 20px auto;
  width: 96vw;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-bottom: 10%;
  display: flex;
  justify-content: space-around;
  align-items: stretch; /* Ensure items stretch to fill the container */
  background: linear-gradient(to right, #14154d 0%, #14154d 70%);
  box-shadow: 10px 10px 50px #000, inset 0 0 50px #000;
  border-radius: 50px;

}

.bottom-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../media/cardbg.png') center/cover no-repeat;
  opacity: 0.1;
  z-index: 0;
}

.bottom-contact-container {
  border: none;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.bottom-contact-container, .media-assets-container {
  flex: 1;
  margin: 0 20px;
  padding: 80px 60px;
  position: relative;
  border: 2px solid #050505;
  border-radius: 50px 100px;
  background-color: #111;
  overflow: hidden;
  box-shadow: inset 0 0 50px #000, 0 0 50px #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly inside each container */
  text-align: center;
}

.bottom-contact-container::before, .media-assets-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url('../media/cardbg.png');
  background-size: cover;
  z-index: 0;
  opacity: 0.1;
}

.bottom-contact-container h2, 
.media-assets-container h2 {
  font-size: 2.5rem;
  color: #dad1ff;
  text-shadow: 2px 2px 4px #2082CA;
  font-family: 'Press Start 2P', cursive;
  margin-bottom: 20px;
  line-height: 3.5rem;
}

.bottom-contact-container p, 
.media-assets-container p {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 30px;
}

.media-folder-button, .collab-link {
  display: inline-block;
  background-color: #dad1ff;
  padding: 10px 20px;
  border-radius: 8px 20px;
  text-decoration: none;
  margin-right: 15px;
  font-size: 1.5rem;
  transition: all 0.15s ease;
  cursor: pointer;
  z-index: 10;
  position: relative;
  color: #0E110E ;
  box-shadow: 0 0 8px #2082CA;
  width: fit-content;
  margin: 0 auto;
}

.media-folder-button:hover, .collab-link:hover {
  opacity: 0.9;
  transform: scale(1.01);
}

@media (max-width: 768px) {
  .bottom-section {
    flex-direction: column;
  }
  
  .bottom-contact-container, .media-assets-container {
    margin-bottom: 20px;
  }
}

/* Additional responsiveness */
@media (max-width: 1500px) {

  .bottom-section {
    padding: 20px 0; 
    width: 90vw;
    margin-top: 120px;

  }

  .bottom-contact-container h2, .media-assets-container h2 {
    font-size: 1.5rem; /* Smaller font size for very small devices */
    margin-bottom: 15px;
    line-height: 2.5rem;
  }

  .bottom-contact-container p, .media-assets-container p {
    font-size: 1.3rem; /* Adjust font size for paragraph text */
  }

  .media-folder-button, .collab-link {
    padding: 8px 16px; /* Adjust button padding */
    font-size: 1.3rem; /* Adjust button font size */
  }

  .bottom-contact-container, .media-assets-container {
    padding: 80px 20px;
    overflow: hidden;
    box-shadow: inset 0 0 50px #000;

  }

}


