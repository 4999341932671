.top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 60px 100px;
  color: white;
  background: linear-gradient(to right, #14154d 0%, #0f0f0f 70%);
  width: 96vw;
  box-sizing: border-box;
  box-shadow:  10px 10px 50px #000 ,inset 0 0 50px #000;
  border-radius: 50px;
  margin: 20px 40px;
  position: relative;
  overflow: hidden;
  margin-top: 80px;
  height: 85vh;

}

.top-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../media/cardbg.png') center/cover no-repeat;
  opacity: 0.1;
  z-index: 0;
}

.content {
  z-index: 2;
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;

  
}

.content h1 {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px #572dff;
  font-family: 'Press Start 2P', cursive;
  letter-spacing: 2px;
  line-height: 1.2; /* Adjust line height for proper spacing */
}


.nameh1 {
  color: #dad1ff;
  text-align: left;
}

.keyword {
  color: #dad1ff;
  font-family: 'Press Start 2p', cursive;
  text-shadow: 0 0 5px #572dff;
  font-size: 1.1rem;
}

.content h2 {
  font-size: 1.5rem;
  text-align: left;
  font-weight: normal;
  max-width: 50%;
  line-height: 1.5;

}

.content h3 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: left;
}

.cta-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 20px 40px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;
  font-size: 1.75rem;
  border-radius: 8px 15px;
  box-shadow: 0 0 5px #572dff;
  transition: .1s ease-in-out;
  margin-top: 50px;
}

.cta-button:hover {
  background-color: #222;
  box-shadow: 0 0 15px #572dff;
  scale: 1.01;
}

.btn1 {
  background-color: #dad1ff;
  color: black;
}

.btn1:hover {
  background-color: #fff;
}

.cta-button-container {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
}

.image-container {
  z-index: 1;
  width: fit-content;
  bottom: 0;
  position: absolute;
  right: 0;
  text-align: right;
}

.image-container img {
  height: 80%;
  width: 100%;
  max-height: calc(85vh + 75px);
  object-fit: cover;
}



.top-right-image,
.middle-right-image {
/* Shared styles */
position: absolute;
max-width: 60%;
max-height: 60%;
opacity: 0.1;
border-radius: 50%; /* Makes the element circular */
animation: glowing-ring 30s infinite alternate;
}

.top-right-image {
bottom: -10%;
left: -0%;
transform: rotate(25deg) !important; /* Rotate 15 degrees to the left */

}

.middle-right-image {
top: -10%;
left: 30%;
width: 100%;
height: auto;
max-width: 750px;
max-height: 750px;
opacity: .05;
transform: rotate(-25deg) !important; /* Rotate 15 degrees to the left */

}



/* Keyframes for animated glowing ring */
@keyframes glowing-ring {
0% {
  box-shadow: 0 0 5px 0 #572dff;
  transform: rotate(5deg);
}
100% {
  box-shadow: 0 0 20px 10px #572dff;
  transform: rotate(5deg);
}
}

/* Media queries to handle screens from 1500px down to mobile sizes */

@media (max-width: 2300px) {
.image-container img {
  width: 80%;
}
}



/* Large screens, smaller than 1500px */
@media (max-width: 1500px) {
.top-section {
    padding: 30px 50px;
}

.top-section h1 {
    font-size: clamp(2rem, 5vw, 3rem);
}

.top-section p,
.top-section a,
.top-section .button-text {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
}

}



/* Extra extra small screens, smaller than 576px */
@media (max-width: 1200px) {
.content {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.top-section {
  background: linear-gradient(to right, #14154d 0%, #0f0f0f 70%);
    color: #FFFFFF; /* White text for readability */
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    text-align: center;
    position: relative; /* For absolute positioning of the image */
    height: fit-content;
  box-shadow: 10px 10px 50px #000 ,inset 0 0 50px #000;
  border: none;
  width: 90vw;
}

.top-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../media/cardbg.png') center/cover no-repeat;
    opacity: 0.1; /* Background image with a dark overlay */
    z-index: 0; /* Ensure the content appears above the background */
}

.top-section .nameh1 {
    font-size: 36px; /* Prominent font size for the main title */
    margin: 16px 0; /* Spacing for clarity */
    font-weight: bold; /* Bold font for emphasis */
}

.top-section .cta-button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px; /* Space above the buttons */
    margin: 20px auto;
    justify-content: center;
    
}

.top-section .cta-button {
    font-size: 25px; /* Button text size */
    margin: 8px 0; /* Space between buttons */
    border-radius: 15px; /* Rounded edges for buttons */
    border: none;
    
}



.top-section .social-icons-container {
    display: none; /* Hide social icons on the main screen for a cleaner look */
}

/* Jake's code */
.cta-h2 .text {
  font-size: 24px;
}

.cta-h2 .keyword {
  font-size: 18px;
}

.cta-h2 {
  min-width: 100%;
}

.image-container {
  opacity: 1;
  width: 100%;
  position: initial;
  height: fit-content;
  transform: translate(0, 0);
  text-align: center;
}

.image-container img {
  z-index: 1;
  border-radius: 50%;
  border: 10px solid #111;
  background-color: #050505;
  aspect-ratio: 1/1;
  height: 300px;
  width: auto;
  box-shadow: 0 0 10px #050505;


}

}