.top-sponsors-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 100px;
    color: white;
    background: linear-gradient(to right, #14154d 0%, #0f0f0f 70%);
    width: 96vw;
    box-sizing: border-box;
    box-shadow: 10px 10px 50px #000, inset 0 0 50px #000;
    border-radius: 50px;
    margin: 80px auto 20px;
    position: relative;
    overflow: hidden;
  }

  .top-sponsors-section::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../media/l3gionWBG.jpg') center/cover no-repeat;
    opacity: 0.03;
    z-index: 0;
    height: 500px;
    width: 500px;
    rotate: 340deg;
  }
  
  .section-title {
    color: #dad1ff;
    font-size: 4rem;
    font-family: 'Press Start 2P', cursive;
    text-shadow: 2px 2px 4px #dad1ff;
    margin-bottom: 60px;
    text-align: center;
  }
  
  .sponsors-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .sponsor-card {
    position: relative; /* Set relative position for the overlay */
    background: url('../media/sponsors/wilder-world-card-cover3.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    border: none;
    padding: 20px;
    text-align: center;
    width: 250px;
    color: #000;
    font-family: 'Arial', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden; /* Ensure overlay stays within rounded corners */
    height: 350px;
    width: 250px;
}

/* Name label */
.sponsor-card h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff; /* White text for better contrast */
    margin: 15px 40px;
    text-transform: uppercase;
    position: absolute; /* Ensure it’s above the overlay */
    z-index: 1;
    bottom: 30px;
    left: 0;
    width: fit-content;
    text-align: left;
    
}

.sponsor-card h2::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translate(-100%, -50%);
    width: 50%;
    height: 2px;
    background: #fff;
}



/* Role label */
.sponsor-card p {
    font-size: 0.9em;
    color: #ccc; /* Light grey for the role text */
    text-transform: uppercase;
    font-weight: lighter;
    margin: 0;
    position: relative; /* Ensure it’s above the overlay */
    z-index: 1;
}

.sponsor-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px #dad1ff;
}



  .sponsor-logo {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .sponsor-name {
    color: #dad1ff;
    font-size: 1.5rem;
    font-family: 'Press Start 2P', cursive;
    text-shadow: 1px 1px 2px #dad1ff;
  }
  
  @media (max-width: 1500px) {
    .top-sponsors-section {
      padding: 30px 50px;
    }
  
    .section-title {
      font-size: 2.5rem;
    }
  
    .sponsor-card {
      width: 200px;
      padding: 15px;
    }
  
    .sponsor-name {
      font-size: 1.25rem;
    }
  }
  
  @media (max-width: 1200px) {
    .section-title {
      font-size: 2rem;
    }
  
    .sponsor-card {
      width: 180px;
      padding: 10px;
    }
  
    .sponsor-name {
      font-size: 1rem;
    }
  }
  