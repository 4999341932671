.middle-section {
    background: linear-gradient(to bottom, #0f0f0f 30%, #14154d 70%); /* Transition from purple to #0E110E  */
    color: white;
    padding: 80px 20px; /* Adjust padding as needed */
    text-align: center;
    box-shadow: 10px 10px 50px #000, inset 0 0 50px #000;
    border-radius: 50px;
    margin: 40px;
    position: relative;
    overflow: hidden;
    width: 96vw;
    box-sizing: border-box;
    margin-top: 120px;


  }

  .middle-section::before {
    background-image: url('../media/cardbg.png');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: 0;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .section-title {
    font-family: 'Press Start 2p', cursive;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px #2082CA;
    letter-spacing: 2px;
    color: #dad1ff;
  }

  .affiliate-links-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0;
    z-index: 10;
    position: relative;
  }

.affiliate-image {
  max-width: 100%; /* Makes the image responsive */
  max-height: 100%; /* Limits the image size to its container */
  display: block; /* Removes extra space below the image */
  object-fit: cover; /* Ensures the aspect ratio is preserved while covering the entire parent */
}

.disclaimer {
  color: #dad1ff;
  border-bottom: 3px solid #333;
  margin: 0 auto;
  padding-bottom: 30px;
  width: 80%;
}

.middle-section .blockchain-grid {
  z-index: 0;
}

/* Extra extra small screens, smaller than 576px */
@media (max-width: 1500px) {
  .middle-section {
    padding: 10px 20px; /* Adjust padding as needed */
  }

  .schedule-title {
    width: 80%;
  }

  .middle-section {
    position: relative;
    width: 90vw;

  }

  .middle-section::before {
    background-image: url('../media/cardbg.png');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: 0;
    opacity: 0.1;
  }
  

}