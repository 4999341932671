.blockchain-grid {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.03;
    transform: translateX(30%);
  }
  
  .node {
    fill: #ffffff;
    /* Initial state of the node, you can add more styles */
  }
  
  /* Animation that changes the fill color and size of the nodes */
  @keyframes pulse {
    0%, 100% {
      fill: #ffffff;
      r: 1;
    }
    50% {
      fill: #0f62fe; /* Blockchain blue */
      r: 2;
    }
  }
  
  /* Apply the animation to each node, with staggered delays for a 'network' effect */
  .node {
    animation: pulse 2s infinite;
  }
  
  /* Example of staggering the animations - you would generate these classes programmatically or write them out */
  .node-0-0 { animation-delay: 0s; }
  .node-0-1 { animation-delay: 0.1s; }
  /* ... and so on for each node */
  