/* CombinedSections.css */

/* Section Styles */
.section-combined {
    color: #333;
    font-family: 'Press Start 2P', cursive;
    text-align: center;
    padding: 60px;
    box-sizing: border-box;
    margin: 20px auto;
    position: relative;
    border-radius: 15px;
}

/* Container Styles */
.container {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    flex-direction: row;
}

/* Columns Layout */
.columns {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-evenly;
    margin-top: 30px;
    overflow: hidden;
}

.column {
    border: 2px solid #050505;
    padding: 50px 40px;
    border-radius: 50px 100px;
    margin-bottom: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    max-width: 40%;
    color: #0E110E;
    z-index: 99;
    background-color: #111;
    box-shadow: inset 0 0 50px #000, 0 0 50px #000;
    margin-top: 50px;
}

.column::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../media/cardbg.png');
    background-size: cover;
    z-index: -1;
    opacity: 0.1;
}

.column h2 {
    color: #2082CA;
    font-size: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    letter-spacing: 2px;
    line-height: 180%;
}

.column p {
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 3rem;
    color: white;
}

/* Discord Icon */
.discord::after {
    background-image: url('../media/l3gion1x1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    aspect-ratio: 1/1;
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 75px;
    transition: opacity 0.3s ease;
    transform: translate(-25%, -25%);
    box-shadow: 0 0px 18px #2082CA;
    z-index: 100;
}

/* Text Styles */
.r3wind,
.l3gion {
    color: #dad1ff;
    font-family: 'Press Start 2P', cursive;
    font-size: 1.5rem;
    text-shadow: 2px 2px 4px #2082CA;
    line-height: 110%;
    display: block;
}

/* Button Styles */
.btn {
    padding: 10px 20px;
    border-radius: 8px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 0px 8px #2082CA;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.btn-primary,
.btn-secondary {
    background-color: #dad1ff;
    color: #0E110E;
}

.btn-primary:hover,
.btn-secondary:hover {
    box-shadow: 0 0px 18px #2082CA;
    opacity: 0.9;
    transform: scale(1.01);
}

/* Background Logo Images */
.card-container {
    position: relative;
    /* Other styles for your container */
}

.circular-image {
    position: absolute;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    opacity: 0.08;
    overflow: hidden;
    animation: float 6s ease-in-out infinite;
}

/* Floating Animation */
@keyframes float {
    0% {
        transform: translateY(0);
        opacity: 0.08;
    }
    50% {
        transform: translateY(-20px);
        opacity: .15;
    }
    100% {
        transform: translateY(0);
        opacity: 0.08;
    }
}

/* Position and Animate Each Image */
.container .circular-image:nth-child(1) {
    top: -25px;
    left: 20%;
    animation-duration: 7s;
    animation-delay: 0s;
}

.container .circular-image:nth-child(2) {
    bottom: -100px;
    left: 30%;
    animation-duration: 8s;
    animation-delay: 1s;
}

.container .circular-image:nth-child(3) {
    top: -125px;
    right: 20%;
    animation-duration: 6s;
    animation-delay: 2s;
}

.container .circular-image:nth-child(4) {
    top: -75px;
    right: 40%;
    animation-duration: 7.5s;
    animation-delay: 1.5s;
}

.container .circular-image:nth-child(5) {
    bottom: -125px;
    left: 50%;
    animation-duration: 6.5s;
    animation-delay: 2.5s;
}

.container .circular-image:nth-child(6) {
    top: -50px;
    right: 50%;
    animation-duration: 8s;
    animation-delay: 0.5s;
}

.container .circular-image:nth-child(7) {
    bottom: 150px;
    left: 70%;
    animation-duration: 7s;
    animation-delay: 1s;
}

.container .circular-image:nth-child(8) {
    bottom: -50px;
    right: 40%;
    animation-duration: 6s;
    animation-delay: 2s;
}

.container .circular-image:nth-child(9) {
    bottom: 125px;
    left: 90%;
    animation-duration: 8.5s;
    animation-delay: 0s;
}

.container .circular-image:nth-child(10) {
    bottom: 50px;
    right: 90%;
    animation-duration: 7.5s;
    animation-delay: 1.5s;
}

.container .circular-image:nth-child(11) {
    bottom: -75px;
    left: 110%;
    animation-duration: 6.5s;
    animation-delay: 2.5s;
}

.container .circular-image:nth-child(12) {
    bottom: -25px;
    right: 110%;
    animation-duration: 8s;
    animation-delay: 0.5s;
}

.container .circular-image:nth-child(13) {
    bottom: -60px;
    left: 40%;
    animation-duration: 7s;
    animation-delay: 1s;
}

.container .circular-image:nth-child(14) {
    top: -50px;
    right: 30%;
    animation-duration: 6s;
    animation-delay: 2s;
}

.container .circular-image:nth-child(15) {
    top: -30px;
    left: 30%;
    animation-duration: 8.5s;
    animation-delay: 0s;
}

.container .circular-image:nth-child(16) {
    bottom: 75px;
    right: 150%;
    animation-duration: 7.5s;
    animation-delay: 1.5s;
}

/* Responsive Styles */
@media (max-width: 1500px) {
    .section-combined {
        padding: 0;
        margin: 0;
        margin-top: 120px;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .container {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .columns {
        width: 100%;
        flex-direction: column;
    }

    .column {
        max-width: 100%;
        padding: 80px 20px;
        margin: 40px 20px;
    }
}
